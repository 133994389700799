import styled from "@emotion/styled";
import React from "react";

const StickyButtonWrapper = styled.div`
  padding: 0.5rem;
  background-color: var(--kenkoPureWhite);
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 9999;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: unset;
  padding: 13px 20px;

  .link {
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    line-height: 20px;
    padding: 14px 32px;
    border-radius: 8px;
    text-decoration: none;
    background-color: var(--kenkoGolden);
    color: var(--kenkoDeepTeal);
  }
`;

type StickyCtaButtonPropsType = {
  content: string;
  navigateHandler: () => void;
};

export const StickyCtaButton = ({
  content,
  navigateHandler,
}: StickyCtaButtonPropsType) => {
  return (
    <StickyButtonWrapper>
      <a onClick={navigateHandler} className="link">
        {content}
      </a>
    </StickyButtonWrapper>
  );
};
