import styled from "@emotion/styled";
import React from "react";
import { imageParser } from "../../../shared/utils/image.utils";
import ReactPlayer from "react-player";
import { videoWrapper } from "../YouTubeSection/YouTubeSection.styles";
import { Grid } from "@mui/material";

const YouTubeButton = styled.button`
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
`;

const KenkoVerseCardWrapper = styled.div`
  position: relative;

  @media (max-width: 600px) {
    width: 325px;
  }

  .kenko-verse-image {
    position: absolute;
    top: -5rem;
    left: 1.5rem;
    z-index: 0;
    max-width: 100%;
    height: auto;

    @media (max-width: 600px) {
      height: 250px;
      width: 250px;
      top: -1.5rem;
    }
  }

  .kenko-verse-container {
    height: 300px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    background: #bed0a2;
    border-radius: 16px;
    border: 1px solid #003032;
    box-shadow: 8px 8px 0px 0px #003032;
  }

  .kenko-verse-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5rem;
  }

  .kenko-verse-info-container {
    padding: 14px;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    border-radius: 0px 0px 16px 16px;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(25px);
    overflow: hidden;
  }

  .kenko-verse-info-title {
    color: #243242;
    font-family: Figtree;
    font-size: 22px;
    font-weight: 700;
    margin: 0;

    @media (max-width: 600px) {
      font-size: 16px;
    }
  }

  .kenko-verse-info-name {
    color: #003032;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0;
    padding-left: 0.5rem;
    border-left: 4px solid #ff7056;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
`;
type KenkoVerseCardPropsType = {
  image: string;
  link: string;
  name: string;
  title: string;
  ytIcon: string;
  // eslint-disable-next-line no-unused-vars
  handleKenkoVerseCard: (title: string) => void;
  isButtonClicked: boolean;
};

export const KenkoVerseCard = ({
  image,
  link,
  name,
  title,
  ytIcon,
  handleKenkoVerseCard,
  isButtonClicked,
}: KenkoVerseCardPropsType) => {
  const VideoWrapper = styled(Grid)(videoWrapper);

  return (
    <KenkoVerseCardWrapper>
      {isButtonClicked ? (
        <VideoWrapper item xs={12}>
          <ReactPlayer
            url={link}
            width="100%"
            height="100%"
            playing={true}
            controls={true}
          />
        </VideoWrapper>
      ) : (
        <div className="kenko-verse-container">
          <img
            className="kenko-verse-image"
            src={imageParser(`kenko_verse/${image}`)}
            alt="youtube thumbnail"
          />
          <div className="kenko-verse-info-container">
            <div className="kenko-verse-info">
              <p className="kenko-verse-info-title">{title}</p>
              <p className="kenko-verse-info-name">{name}</p>
            </div>
            <YouTubeButton onClick={() => handleKenkoVerseCard(title)}>
              <img
                className="yt-button-image"
                src={imageParser(`kenko_verse/${ytIcon}`)}
                alt="yt button"
              />
            </YouTubeButton>
          </div>
        </div>
      )}
    </KenkoVerseCardWrapper>
  );
};
