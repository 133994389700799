export const benefitsArray = [
  {
    image: "doctor_fees.svg",
    altText: "Doctor fees",
    title: "Doctor fees",
    description:
      "Visit a doctor or  consult them virtually - we got you covered.",
  },
  {
    image: "medicine_bills.svg",
    altText: "medicine bills",
    title: "Medicine bills",
    description: "We’ll pay for all of your medical bills.",
  },
  {
    image: "lab_tests.svg",
    altText: "lab tests",
    title: "Lab tests",
    description:
      "Radiology, ECG or any diagnostic test - we’ll take care of it.",
  },
  {
    image: "shoho_store.svg",
    altText: "shoho store",
    title: "Daily essentials",
    description:
      "Baby care, personal care, diabetic care, skin care, nutrition & more!",
  },
  {
    image: "hospitalization.svg",
    altText: "hospitalization",
    title: "Hospitalisation",
    description: "20 Lac+ coverage with truecashless across 1500+ hospitals.",
  },
];

export const kenkoVerseArr = [
  {
    image: "rohit.png",
    title: "During an emergency, help arrived in 15 mins!",
    name: "Rohit Gupta",
    ytIcon: "yt_button.png",
    isButtonClicked: false,
    link: "https://www.youtube.com/watch?v=NwHpXyi9NcY",
  },
  {
    image: "shruti.png",
    title: "Kenko saved me ₹30000 on Ultrasound tests!",
    name: "Shruti Aggarwal",
    ytIcon: "yt_button.png",
    isButtonClicked: false,
    link: "https://www.youtube.com/watch?v=1XFvc0TNESs",
  },
  {
    image: "girish.png",
    title: "I got 100% covered for my Knee surgery!",
    name: "Girish Chaturvedi",
    ytIcon: "yt_button.png",
    isButtonClicked: false,
    link: "https://www.youtube.com/watch?v=T7A6u7APGvc",
  },
];
