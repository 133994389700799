// import { desktopPadding, mobilePadding } from "..";
import { biege50 } from "../../../shared/styles/colors";
import { kenkoPureWhite } from "./../../../shared/assets/colors";

// padding
const desktopPadding = "5vw";
const mobilePadding = "5vw";

export const sliderCls = {
  height: "auto",

  "& .slick-arrow.slick-prev": {
    background: " #F4F7ED",
    height: "fit-content",
    borderRadius: "50%",
    position: "absolute",
    zIndex: "100",
    border: "1px solid #E3EBD2",
  },
  "& .slick-arrow.slick-next": {
    background: " #F4F7ED",
    height: "fit-content",
    borderRadius: "50%",
    position: "absolute",
    zIndex: "100",
    marginRight: "1%",
    border: "1px solid #E3EBD2",
  },
  "& .slick-disabled": {
    opacity: 0.5,
    cursor: "auto",
  },
  "& .slick-dots li.slick-active a svg path": {
    fillOpacity: 1,
  },
  "& .slick-dots": {
    position: "unset",
  },
};

export const heroSectionSlider = () => `
  color: ${kenkoPureWhite};
  padding: 160px ${desktopPadding} 50px;
  position: relative;
`;

export const heroSectionSliderMobile = `
  background-color: var(--kenkoDeepTeal);
  color: var(--kenkoPureWhite);
  padding: 80px ${mobilePadding} 35px ${mobilePadding};
  margin-top: -64px !important;
  margin-bottom: 0 !important;

  .hero-mobile-container {
    margin-top: 50px;
  }

  @media (max-width: 768px) { 
    padding: 0px;
    margin-top: 0 !important; 
  }
`;

export const heroActionBtn = `
  width: 248px;
  height: 57px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-radius: 8px !important;
  // CHECK
  font-size: 24px !important;

  @media (max-width: 600px) {
    width: 184px;
    height: 44px;
    font-size: 16px !important;
    font-weight: 600 !important;
  }
`;

export const heroIcons = ({ newFlow }: any) => `
  position: absolute;
  top: ${newFlow ? "600px" : "545px"};
  left: 50%;
  transform: translate(-50%, 0);
  background-color: ${biege50};
  box-shadow: 0px 18px 38px rgba(123, 123, 123, 0.14);
  border-radius: 20px;
  max-width: 887px;
  width: 100%;
  padding: 25px 70px;
  margin: 0 auto;

  @media (max-width: 600px) {
    top: ${newFlow ? "600px" : "580px"};
    padding: 28px 20px;
    max-width: 90%;
  }
`;
