import { memo, useEffect } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useRouter, useViewport } from "./../../../shared/hooks";
import Button from "../../../shared/components/Button/Button";
import Typography from "../../../shared/components/Typography/Typography";
import { heroActionBtn, heroSectionSlider } from "./HeroSection.styles";
import { kenkoCarrot, kenkoSprout } from "../../../shared/assets/colors";
import { styled } from "@mui/system";
import { imageParser } from "../../../shared/utils/image.utils";
import { useState } from "react";
import { backendEvents } from "../../../shared/constants/backend-events";
import {
  HERO_SIGNUP_PAGE,
  KENKO_SCORE_PAGE,
  MY_PLANS_PAGE,
  PLAN_SELECTION,
  SIGNUP_PAGE,
} from "../../../shared/constants/routes";
import { OTP_VERIFIED } from "../../../shared/constants/enums";
import { shouldRedirectToNewFlow } from "../../../shared/utils/helper.utils";
import tracker from "../../../shared/utils/tracker.utils";
import { backendDrivenEvents } from "../../../slices/onboarding.slice";
import { useInView } from "react-intersection-observer";

const HeroWrapper = styled(Grid)(heroSectionSlider);
const HeroTextCaptionWrapper = styled(Grid)`
  text-align: center;
  font-family: "Figtree";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  & p {
    margin: 0;
  }

  .info-wrapper {
    margin-top: 56px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 28px;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #bed0a2;
  }

  .info-divider {
    width: 2px;
    background-color: #efedd8;
    margin: 5px 0;
  }

  .info-states {
    font-size: 22px;
    font-weight: 700;
  }

  .info-text {
    font-size: 18px;
    font-weight: 400;
  }
`;
const HeroActionBtnWrapper = styled(Button)(heroActionBtn);

export const HeroSection = () => {
  const { ref, inView } = useInView();
  const dispatch = useDispatch();

  useEffect(() => {
    if (inView) {
      dispatch(
        backendDrivenEvents({
          ...backendEvents.HOMEPAGE_HERO_SECTION,
        })
      );
    }
  }, [inView, dispatch]);

  const router = useRouter();
  const auth = useSelector((state: any) => state.auth);

  const matchesMD = useViewport(1200).below;

  const [loaded, setLoaded] = useState(false);

  const utm = useSelector((state: any) => state.utmReducer);

  const newFlow = shouldRedirectToNewFlow(utm);

  const heroSectionItems = [
    {
      id: 1,
      content: (
        <div>
          <Typography
            style={{
              fontWeight: 700,
              fontSize: "46px",
              margin: "0",
            }}
          >
            Make Your
          </Typography>
          <Typography
            style={{
              fontWeight: 700,
              fontSize: "46px",
              margin: "0",
            }}
          >
            Medical Bills
            <span
              style={{
                paddingLeft: "10px",
                fontSize: "46px",
                fontWeight: 700,
                color: kenkoSprout,
              }}
            >
              Zero
            </span>
          </Typography>

          <Typography
            style={{
              fontSize: "26px",
              fontWeight: 300,
              margin: "16px 0px 26px",
              color: kenkoCarrot,
            }}
          >
            Plans starting from
            <span style={{ fontWeight: 700 }}>
              {newFlow ? " ₹6/day" : " ₹30/day"}
            </span>
          </Typography>

          <HeroActionBtnWrapper
            label="Subscribe now"
            variant="contained"
            size="xl"
            style={{
              color: "#202020",
              fontFamily: "Figtree",
              fontSize: "2px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "140%",
              boxShadow: "unset",
            }}
            onClick={() => {
              tracker.pushAll("web_buy_now", {
                Page_Url: window.location.href,
                Location: "HeroSection",
                platform_type: "desktop",
              });

              if (auth?.userInfo?.userId) {
                let url = newFlow ? PLAN_SELECTION : KENKO_SCORE_PAGE;
                if (
                  auth?.onBoardingInfo?.userPlanOnboardingData[0]?.planDetails
                    ?.userPlanStatus === "SUBSCRIBER" ||
                  auth?.onBoardingInfo?.userPlanOnboardingData[0]?.planDetails
                    ?.userPlanStatus === "PAID"
                )
                  url = MY_PLANS_PAGE;

                router.push(url);
              } else {
                router.push(
                  auth?.sendOTP?.phone && auth?.sendOTP?.status === OTP_VERIFIED
                    ? SIGNUP_PAGE
                    : HERO_SIGNUP_PAGE
                );
              }
            }}
          />

          <HeroTextCaptionWrapper>
            <div className="info-wrapper">
              <div className="info-container">
                <p className="info-states">3 Lakh+</p>
                <p className="info-text">Subscribers</p>
              </div>
              <div className="info-divider"></div>
              <div className="info-container">
                <p className="info-states">100 Crore+</p>
                <p className="info-text">Worth of benefits settled</p>
              </div>
              <div className="info-divider"></div>
              <div className="info-container">
                <p className="info-states">9000+</p>
                <p className="info-text">Network hospital</p>
              </div>
            </div>
          </HeroTextCaptionWrapper>
        </div>
      ),
      image: (
        <img
          src={
            loaded
              ? imageParser("hero_section/hero_image.webp")
              : imageParser("hero_section/hero_image_small.webp")
          }
          alt="Kenko Healthcare - Innovating for Better Health"
          style={{ margin: "0 0 0 10px", position: "absolute" }}
          onLoad={() => {
            setLoaded(true);
          }}
        />
      ),
    },
  ];

  return (
    <HeroWrapper ref={ref} container>
      <Grid sx={{ fontFamily: "Figtree" }} item sm={12}>
        {heroSectionItems.map((item) => (
          <div key={item.id}>
            <Grid container key={item.id} style={{ display: "flex" }}>
              <Grid item sm={6} style={{ marginTop: "50px" }}>
                {item.content}
              </Grid>

              <Grid
                item
                sm={6}
                style={{ marginTop: matchesMD ? "50px" : 0, display: "flex" }}
              >
                {item.image}
              </Grid>
            </Grid>
          </div>
        ))}
      </Grid>
    </HeroWrapper>
  );
};

export default memo(HeroSection);
