import React, { useEffect, useState } from "react";
import {
  KenkoVerseSection,
  KenkoVerseTitle,
} from "./KenkoVerseSection/KenkoVerseSection";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import { backendDrivenEvents } from "../../slices/onboarding.slice";
import { backendEvents } from "../../shared/constants/backend-events";
import { KenkoVerseCard } from "./KenkoVerseSection/KenkoVerseCard";
import { kenkoVerseArr } from "../../shared/constants/home";

export const KenkoVerse = () => {
  const { ref, inView } = useInView();
  const dispatch = useDispatch();
  const [kenkoVerseData, setKenkoVerseData] = useState(kenkoVerseArr);

  const handleKenkoVerseCard = (title: string) => {
    setKenkoVerseData((prev) =>
      prev.map((item) => ({
        ...item,
        isButtonClicked: item.title === title ? true : false,
      }))
    );
  };

  useEffect(() => {
    if (inView) {
      dispatch(
        backendDrivenEvents({
          ...backendEvents.HOMEPAGE_KENKO_VERSE_SECTION,
        })
      );
    }
  }, [inView]);

  return (
    <div ref={ref}>
      <KenkoVerseTitle>Straight from the Kenkoverse</KenkoVerseTitle>
      <KenkoVerseSection>
        {kenkoVerseData.map(
          ({ image, link, name, title, ytIcon, isButtonClicked }) => (
            <KenkoVerseCard
              isButtonClicked={isButtonClicked}
              handleKenkoVerseCard={handleKenkoVerseCard}
              key={title}
              image={image}
              link={link}
              name={name}
              title={title}
              ytIcon={ytIcon}
            />
          )
        )}
      </KenkoVerseSection>
    </div>
  );
};
