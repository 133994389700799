import { kenkoDeepTeal, kenkoPureWhite } from "../../../shared/assets/colors";
import { imageParser } from "../../../shared/utils/image.utils";

export const mockupSection = {
  background: `url('${imageParser(
    "mockup-screens-new.webp"
  )}') 0 0 no-repeat fixed`,
  backgroundSize: "cover",
  width: "100%",
  height: "fit-content",
  overflow: "hidden",
};

export const mockupOverlay = {
  width: "100%",
  height: "100%",
  backgroundColor: "#003032d9",
  zIndex: 2,
  overflow: "hidden",
};

export const mockupHeading = {
  color: kenkoPureWhite,
  margin: 0,
};

export const mockupVideoItem = {
  backgroundColor: kenkoDeepTeal,
  padding: "10px",
  marginBottom: "2px",
  width: "100%",
};

export const videoWrapper = `
  max-width: 1080px !important;
  height: 300px;
  margin: 0 auto;
  padding: 0 !important;

  @media (max-width: 600px) {
    max-width: 294px !important;
    height: 300px;
  }

  // Preview
  & .react-player__preview {
    border-radius: 16px;

    @media (max-width: 600px) {
      border-radius: 16px;
    }
  }

  // Video playing
  iframe {
    border-radius: 16px;

    @media (max-width: 600px) {
      border-radius: 16px;
    }
  }
`;
